<template>
    <div class="main">
        <div class="title">发布需求信息-脱密专利</div>
        <div class="top">
            <el-form ref="form" :model="formData" :rules="rules">
                <el-form-item label="需求脱密专利名称" prop="name">
                    <el-input
                        style="width: 400px"
                        placeholder="请输入"
                        v-model="formData.name"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="keyword" label="需求脱密专利包含关键字">
                    <el-input
                        style="width: 400px"
                        placeholder="请输入需求脱密专利包含关键字"
                        v-model="formData.keyword"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="industryClass" label="行业分类">
                    <el-select style="width: 200px" v-model="formData.industryClass" placeholder="请选择">
                        <el-option
                            v-for="item in industryOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="description" label="需求描述" class="block">
                    <el-input
                        style="width: 400px"
                        type="textarea"
                        v-model="formData.description"
                        maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 6, maxRow: 6 }"
                        placeholder="请填写专利摘要描述，500字以内"
                        resize="none"
                    ></el-input>
                </el-form-item>
                <el-form-item label="转化类型" prop="modeId">
                    <el-radio-group v-model="formData.modeId">
                        <el-radio v-for="item in tradingMethodOptions" :key="item.value" :label="item.value">
                            {{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="expectedPrice" label="预算(万元)">
                    <el-input-number
                        v-model="formData.expectedPrice"
                        :min="0"
                        :precision="1"
                        :controls="false"
                        placeholder="请输入"
                        style="margin-right: 10px; width: 200px"
                    >
                    </el-input-number>

                    <el-checkbox v-model="formData.negotiateDirectly">面议</el-checkbox>
                </el-form-item>
                <el-form-item prop="contact" label="联系人">
                    <el-input style="width: 200px" v-model="formData.contact" placeholder="请输入联系人"></el-input>
                </el-form-item>

                <el-form-item prop="phone" label="联系电话">
                    <el-input style="width: 200px" v-model="formData.phone" placeholder="请输入联系电话"></el-input>
                </el-form-item>

                <el-form-item prop="address" label="所在地区">
                    <el-input style="width: 400px" v-model="formData.address" placeholder="请输入所在地址"></el-input>
                </el-form-item>

                <el-form-item prop="email" label="邮箱">
                    <el-input style="width: 200px" v-model="formData.email" placeholder="请输入邮箱"></el-input>
                </el-form-item>
            </el-form>
            <div class="btn">
                <el-button type="primary" @click="onSave">保存</el-button>
                <el-button type="info" @click="$router.go(-1)">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { tradingMethodOptions } from '../../utils/variables';
import { mapState } from 'vuex';
export default {
    created() {
        this.$http
            .post('/setting/byFlag', { flag: 1 })
            .then(res => {
                if (res.length > 0) {
                    res.forEach(item => {
                        this.industryOptions.push({
                            label: item.name,
                            value: item.id
                        });
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
        this.formData = {
            contact: this.userInfo.nickname,
            phone: this.userInfo.phone,
            email: this.userInfo.email
        };
    },
    data() {
        return {
            formData: {},
            rules: {
                name: [{ required: true, message: '请输入专利名称' }],
                keyword: [{ required: true, message: '请输入专利关键字' }],
                industryClass: [{ required: true, message: '请选择行业分类' }],
                description: [{ required: true, message: '请输入专利摘要' }],
                modeId: [{ required: true, message: '请选择转化类型' }],
                expectedPrice: [{ required: true, message: '请输入期望价', trigger: 'blur' }],
                contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
                email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                address: [{ required: true, message: '请输入所在地区' }]
            },
            industryOptions: [],
            tradingMethodOptions
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = {
                ...this.formData,
                caseType: 'DECLASSIFICATION',
                userId: this.userInfo.id,
                contact: this.userInfo.nickname,
                phone: this.userInfo.phone,
                email: this.userInfo.email
            };
            this.saving = true;
            console.log(data);
            this.$http
                .post('/patentDemand/save', data, { body: 'json' })
                .then(() => {
                    this.saving = false;
                    this.$message.success('发布成功');
                    this.show = false;
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        }
    }
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 185px;
    white-space: nowrap;
    color: #313233;
    text-overflow: ellipsis;
    line-height: 40px;
}
/deep/ .el-form-item__error {
    margin-left: 185px;
}
/deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
}
/deep/ .el-button {
    width: 110px;
    margin-left: 25px;
}
.main {
    background-color: #fff;
    margin: 17px 20px 0;
    padding-bottom: 60px;
    .btn {
        text-align: right;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        padding-bottom: 17px;
        line-height: 26px;
        border-bottom: 1px solid @bg;
    }
    .top {
        margin-top: 30px;
    }
}
</style>
