import { render, staticRenderFns } from "./Demandpatent.vue?vue&type=template&id=e918ba88&scoped=true"
import script from "./Demandpatent.vue?vue&type=script&lang=js"
export * from "./Demandpatent.vue?vue&type=script&lang=js"
import style0 from "./Demandpatent.vue?vue&type=style&index=0&id=e918ba88&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e918ba88",
  null
  
)

export default component.exports